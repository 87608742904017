/* eslint @typescript-eslint/no-use-before-define: ["error", "nofunc"] */

import { Carousel } from "@components";
import classNames from "classnames";
import { useVerticalSpacing } from "src/lib/context/VerticalSpacingContext";
import { cn } from "src/utils/tailwind-merge";

import { TextOverlayMedia, TextOverlayMediaProps } from "../TextOverlayMedia";

export interface HeroSectionProps {
  /** a list of multiple text overlay media included */
  textOverlayMediaList: TextOverlayMediaProps[];

  /** if multiple text overlay medias, if the carousel is autoplayed */
  autoPlay?: boolean | undefined;
}

export function HeroSection({
  textOverlayMediaList,
  autoPlay = true,
}: HeroSectionProps) {
  const { hasVerticalSpacing } = useVerticalSpacing();
  if (!textOverlayMediaList.length) return null;

  const lrtClasses = classNames(
    "max-w-screen-king m-auto p-6 full:px-8 full:py-24"
  );

  return (
    <div
      className={cn("grid w-full grid-cols-1 h-fit isolate", {
        "mb-4 md:mb-6": hasVerticalSpacing,
      })}
    >
      {textOverlayMediaList?.length > 1 ? (
        <div className="relative">
          <Carousel
            autoplay={{ enabled: autoPlay, delay: 5000 }}
            className="col-start-1 row-start-1 swiper-items-stretch"
            loop
            navigation={false}
          >
            {textOverlayMediaList?.map((tom) => (
              <div
                className="w-full bg-lightgray h-[550px] md:h-full min-h-[550px] max-h-[850px]"
                key={tom?.name}
              >
                <TextOverlayMedia
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...tom}
                  lrtClassName={lrtClasses}
                  keepDimensions={{ mobile: false, desktop: false }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      ) : (
        <div className="w-full bg-lightgray h-[550px] md:h-full min-h-[550px] max-h-[850px]">
          <TextOverlayMedia
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(textOverlayMediaList[0] || {})}
            lrtClassName={lrtClasses}
            keepDimensions={{ mobile: true, desktop: true }}
          />
        </div>
      )}
    </div>
  );
}
